import { render, staticRenderFns } from "./upcoming-interactions.vue?vue&type=template&id=151b532a&scoped=true"
import script from "./upcoming-interactions.vue?vue&type=script&lang=js"
export * from "./upcoming-interactions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151b532a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InteractionsTable: require('/codebuild/output/src2108204121/src/web/components/interactions/interactions-table.vue').default,ConfirmModal: require('/codebuild/output/src2108204121/src/web/components/confirm-modal.vue').default})
